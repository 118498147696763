.booking-screen {
  .booking-form-section {
    padding: 125px 75px 115px 75px;
    background-color: #f0eae1;
    color: #333;

    .title {
      font-size: 28px;
      font-weight: 500;
      margin-bottom: 5px;
    }

    .subtitle {
      font-size: 16px;
      margin-bottom: 30px;
      opacity: 0.85;

      span {
        font-weight: bold;
        color: rgb(238, 77, 77);
      }
    }

    .booking-form {
      display: flex;
      flex-direction: column;
      gap: 5px;
      max-width: 450px;

      label {
        display: flex;
        align-items: center;
        font-size: 18px;
        margin-top: 10px;

        span {
          font-weight: bold;
          color: rgb(238, 77, 77);
          margin-left: 5px;
        }
      }

      input,
      select,
      textarea {
        margin-top: 5px;
        padding: 10px;
        font-size: 16px;
        line-height: 24px;
        border: 1px solid #ccc;
        border-radius: 5px;
        background-color: #fff;

        &:focus {
          outline: none;
          border-color: #666;
        }
      }

      textarea {
        min-height: 120px;
        resize: vertical;
      }

      button {
        padding: 10px 20px;
        margin-top: 25px;
        font-size: 18px;
        font-weight: 500;
        color: #fff;
        background-color: #333;
        border: none;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
          background-color: #555;
        }
      }
    }
  }

  @media (max-width: 550px) {
    .booking-form-section {
      padding: 100px 15px;

      .title {
        font-size: 24px;
      }

      .subtitle {
        font-size: 16px;
      }

      .booking-form {
        label {
          font-size: 16px;

          input,
          select,
          textarea {
            font-size: 14px;
          }
        }

        button {
          font-size: 16px;
        }
      }
    }
  }
}
