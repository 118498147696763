/* CookieBanner.scss */
.cookie-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #f0eae1;
  color: #333;
  padding: 25px 45px;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;

  .banner-text {
    flex-direction: column;
    width: 75%;
  }

  h4 {
    font-weight: 600;
    font-size: 16px;
  }

  p {
    margin-top: 10px;
    font-size: 14px;
    line-height: 20px;
  }

  &__close-btn {
    background-color: #718476;
    color: #fff;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
    margin: 15px;

    &:hover {
      background-color: #5a6a5f;
    }
  }
}
