.our-prices-section {
  background-color: #5a6e56;
  padding: 75px 125px;

  h2 {
    font-size: 34px;
    font-weight: 300;
    color: white;
    letter-spacing: 0.5px;
    text-align: center;
    margin-bottom: 50px;
  }

  .prices-cards {
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(250px, 1fr)
    ); // Минимальная ширина карточек – 250px
    gap: 25px;
    justify-content: center;
    max-width: 1000px; // Ограничение ширины для центровки и уменьшения размера карточек
    margin: auto;

    .price-card {
      background-color: #f8f2e8;
      text-align: center;
      padding-bottom: 20px;
      border-radius: 5px;
      overflow: hidden;

      img {
        width: 100%;
        aspect-ratio: 1.25;
        object-fit: cover;
        display: block;
      }

      .price-info {
        h3 {
          font-size: 24px;
          font-weight: 500;
          margin-bottom: 10px;
          background-color: #718476;
          color: white;
          padding: 15px;
          margin-bottom: 25px;
        }

        .price {
          font-size: 28px;
          font-weight: 700;
        }

        .period {
          font-size: 16px;
          font-weight: 500;
          color: #888;
        }

        button {
          background-color: #4d5a51;
          color: #fff;
          border: none;
          padding: 14px 20px;
          border-radius: 10px;
          cursor: pointer;
          margin: 20px 10px 10px 10px;
          width: 75%;
        }

        .tax-info {
          font-size: 14px;
          color: #999;
          margin-top: 5px;
        }
      }

      .mehr-erfahren-link {
        display: block;
        margin-top: 25px;
        color: #888;

        &:hover {
          opacity: 0.75;
        }
      }
    }
  }

  // Адаптивные стили
  @media (max-width: 1300px) {
    .prices-cards {
      max-width: 675px; // Ограничиваем ширину для 2 карточек в ряд
    }
  }

  @media (max-width: 768px) {
    padding: 50px 10px;
    .prices-cards {
      max-width: 325px; // Ограничиваем ширину для 1 карточки в ряд
    }
  }
}
