.hero-section {
  display: flex;
  background-color: #f0eae1;
  height: 100vh;
  position: relative;
  max-width: 100vw;

  .background,
  .content {
    transition: opacity 0.3s ease-in-out;
  }

  .fade-in {
    opacity: 1;
  }

  .fade-out {
    opacity: 0;
  }

  .background img {
    height: 100%;
    width: 45.5vw;
    object-fit: cover;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end; /* Исправлено на 'flex-end' */
    padding: 100px;
    width: 38%;

    .heading {
      transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
      opacity: 0;
    }

    .slide-in {
      opacity: 1;
      transform: translateY(0); /* Плавный подъём текста */
    }

    .slide-out {
      opacity: 0;
      transform: translateY(-20px); /* Текст уходит вниз */
    }

    h3 {
      font-size: 36px;
      font-weight: 400;
    }

    button {
      outline: none;
      border: none;
      font-size: 18px;
      background-color: transparent;
      transition: opacity 0.4s ease;
      cursor: pointer;
      opacity: 1; /* Статичный элемент с постоянной видимостью */

      &:hover {
        opacity: 0.5;
      }
    }
  }

  /* Медиа-запросы для адаптации под разные устройства */
  @media (max-width: 1200px) {
    .background img {
      width: 50vw;
    }

    .content {
      width: 35%;
      padding: 80px;

      h3 {
        font-size: 32px;
      }

      button {
        font-size: 16px;
      }
    }
  }

  @media (max-width: 768px) {
    padding-top: 50px;
    flex-direction: column;
    height: auto;

    .background img {
      width: 100%;
      height: 50vh;
    }

    .content {
      width: 100%;
      align-items: center;
      padding: 50px;
      height: calc(50vh - 50px);

      h3 {
        font-size: 28px;
        text-align: center;
      }

      button {
        font-size: 16px;
      }
    }
  }
}
