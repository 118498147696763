.facilities-section {
  background-color: #f0eae1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 75px 125px 100px 125px;

  h2 {
    font-size: 34px;
    font-weight: 300;
    color: rgb(0, 0, 0);
    letter-spacing: 0.5px;
    margin-bottom: 50px;
  }

  .facilities-cards {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 5 карточек в строке */
    gap: 20px; /* отступы между карточками */
    justify-items: center; /* Центрирование карточек */

    & > div {
      text-align: center; /* выравнивание содержимого карточки */
      padding: 10px;
      border-radius: 25px;
      transition: all 0.1s ease;

      &:hover {
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.06); /* небольшая тень */
      }

      img {
        width: 40%; /* ширина изображения внутри карточки */
        margin-bottom: 10px; /* отступ между изображением и заголовком */
      }

      h4 {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  /* Медиа-запросы для адаптации под разные экраны */

  @media (max-width: 1300px) {
    .facilities-cards {
      grid-template-columns: repeat(4, 1fr); /* 4 карточки в строке */
    }
  }

  @media (max-width: 768px) {
    .facilities-cards {
      grid-template-columns: repeat(2, 1fr); /* 2 карточки в строке */
    }
  }
}
