@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Tangerine:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,100..900;1,100..900&display=swap");

@import url(./styles/Header.styles.scss);
@import url(./styles/HeroSection.styles.scss);
@import url(./styles/BookSection.styles.scss);
@import url(./styles/CategoriesSection.styles.scss);
@import url(./styles/FacilitiesSection.styles.scss);
@import url(./styles/OurPricesSection.styles.scss);
@import url(./styles/OverviewSection.styles.scss);

@import url(./styles/ApartmentInfoScreen.scss);
@import url(./styles/ContactScreen.styles.scss);
@import url(./styles/BookingScreen.styles.scss);

@import url(./styles/Datenschutz.styles.scss);
@import url(./styles/CookieBanner.styles.scss);

* {
  font-family: "Manrope", sans-serif;

  // outline: 1px solid grey;
}

html {
  scroll-behavior: smooth;
}

.app {
  overflow-x: hidden;
}
