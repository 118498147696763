.book-section {
  position: relative; /* Для позиционирования наложения */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 125px 75px 125px;
  background-image: url("../assets/hero-bg.jpg"); /* Замените URL на ваше изображение */
  background-size: cover; /* Фон занимает весь блок */
  background-position: center;
  background-repeat: no-repeat;

  /* Полупрозрачный зелёный слой */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(50, 50, 50, 0.6); /* Полупрозрачный зелёный цвет */
    z-index: 1; /* Устанавливает слой выше фона */
    backdrop-filter: blur(2px);
  }

  /* Контент секции */
  > h2,
  .book-controls {
    position: relative; /* Слой выше полупрозрачного фона */
    z-index: 2;
  }

  > h2 {
    font-size: 34px;
    font-weight: 300;
    color: white;
    letter-spacing: 0.5px;
  }

  .book-controls {
    margin-top: 50px;
    display: flex;
    align-items: center;
    gap: 15px;

    .control {
      .custom-select {
        > div {
          width: 240px;
          outline: none;
          cursor: pointer;
        }
      }
      .react-datepicker-wrapper {
        border-radius: 5px;
        position: relative;
        top: 2px;
        overflow: hidden;

        input {
          height: 38px;
          width: 240px;
          padding: 0 10px;
        }
        * {
          border: none;
          // outline: n;
        }
      }
    }

    > button {
      outline: none;
      border: none;
      background-color: #ffffff;
      color: #2e2e2e;
      display: inline-block;
      border-radius: 6px;
      height: 38px;
      width: 240px; /* Ограничиваем ширину кнопки */
      // margin: 15px;

      &:hover {
        outline: 1px solid #2e2e2e;
        cursor: pointer;
      }
    }
  }

  /* Адаптация для маленьких экранов */
  @media (max-width: 1200px) {
    padding: 50px 25px; /* Уменьшаем отступы на мобильных устройствах */

    h2 {
      font-size: 28px; /* Уменьшаем размер заголовка */
    }

    .book-controls {
      flex-direction: column; /* Располагаем инпуты и кнопку в колонку */
      align-items: stretch; /* Растягиваем элементы по всей ширине */

      .control {
        .custom-select {
          > div {
            width: 230px;
            outline: none;
          }
        }
        .react-datepicker-wrapper {
          input {
            width: 230px;
          }
        }
      }

      button {
        width: 100%; /* Кнопка занимает всю ширину */
        margin: 30px 0;
      }
    }
  }
}
