.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px 20px 20px 20px;
  background-color: rgb(55, 55, 55);
  color: #fff;
  font-family: Arial, sans-serif;

  &__info {
    text-align: center;
    margin-bottom: 15px;

    h2 {
      font-size: 24px;
      margin-bottom: 10px;
    }

    address {
      font-style: normal;
      line-height: 1.6;
      margin: 10px;
    }

    a {
      color: #fff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    p {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
    }
  }

  &__icon {
    font-size: 18px;
  }

  &__legal {
    text-align: center;
    margin-top: 10px;
    border-top: 1px solid rgb(78, 78, 78);
    padding-top: 20px;
    width: 100%;

    .footer__link {
      background: none;
      border: none;
      color: #718476;
      cursor: pointer;
      font-size: 16px;
      margin-top: 10px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  // Модальные стили
  .modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .modal {
    background: white;
    max-width: 600px;
    width: 90%;
    max-height: 80vh;
    overflow-y: auto;
    border-radius: 8px;
    padding: 25px;
    position: relative;
    color: #333;
  }

  .modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;

    h2 {
      font-size: 1.5rem;
      margin: 0;
    }
  }

  .modal__content {
    // font-size: 0.9rem;
    line-height: 1.5;
  }

  .modal__close {
    background: none;
    border: none;
    font-size: 28px;

    cursor: pointer;
    border-radius: 10px;
    // padding: 3px;
    height: 32px;
    width: 32px;
    // color: #c11717;

    &:hover {
      background-color: #33333316;
    }
  }

  // Адаптивные стили
  @media (max-width: 768px) {
    padding: 25px;

    &__info {
      margin-bottom: 10px;
    }
  }
}
