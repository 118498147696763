.gallery-section {
  background-color: #718476;
  padding: 75px 125px;
  text-align: center;

  h2 {
    font-size: 34px;
    font-weight: 300;
    color: white;
    letter-spacing: 0.5px;
    margin-bottom: 50px;
  }

  .gallery-grid {
    display: flex;
    justify-content: center;
    gap: 20px;

    .gallery-column {
      display: flex;
      flex-direction: column;
      gap: 20px;

      img {
        width: 100%;
        object-fit: cover;
      }

      .horizontal {
        aspect-ratio: 1.65;
      }

      .vertical {
        aspect-ratio: 0.85;
      }

      .square {
        aspect-ratio: 1;
      }
    }
  }

  .gallery-slider {
    display: none; // Скрываем слайдер по умолчанию
  }

  // Для экранов до 1300px
  @media (max-width: 1300px) {
    padding: 50px 30px;
  }

  // Для экранов до 768px
  @media (max-width: 768px) {
    padding: 50px 5px;
    .gallery-grid {
      display: none; // Убираем галерею
    }

    .gallery-slider {
      display: block; // Показываем слайдер
      // width: 100%;
      max-height: 400px;
      img {
        // height: 400px;
        max-height: 400px;
        width: 96%;
        margin-left: 2%;
        overflow: hidden;
        border-radius: 8px;
      }
      * {
        outline: none;
      }

      .slick-arrow {
        &:hover {
          opacity: 0.75;
          background-color: #0000002b;
        }

        z-index: 3;
        border-radius: 3px;
        width: 30px;
        height: 30px;
        padding: 5px;

        * {
          color: rgb(228, 228, 228);
        }
      }

      .slick-next {
        right: 18px;
      }

      .slick-prev {
        left: 18px;
      }
    }
  }

  // Для экранов выше 768px
  @media (min-width: 769px) {
    .gallery-slider {
      display: none; // Скрываем слайдер
    }

    .gallery-grid {
      display: flex; // Показываем галерею
    }
  }
}
