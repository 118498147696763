.apartment-info {
  padding: 125px 75px 115px 75px;
  background-color: #f0eae1;
  color: #333;

  h2 {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 15px;
  }

  .title,
  .address {
    padding-left: 5px;
  }

  a {
    text-decoration: none;
    color: #333;

    &:hover {
      opacity: 0.75;
    }
  }

  .horizontal-blocks {
    display: flex;
    gap: 50px;
    margin-top: 30px;

    > div:first-child {
      width: 35%;
    }

    > div:nth-child(2) {
      width: 40%;
      display: flex;
      flex-direction: column; // Содержимое выстроится вертикально
    }

    .map-container {
      iframe {
        position: static !important;
      }

      flex-grow: 1; // Карта займёт оставшееся пространство по высоте
      border-radius: 20px;
      overflow: hidden;
      background-color: rgb(220, 218, 212);
      margin-bottom: 5px;

      * {
        border: none;
        outline: none;
      }
    }
  }

  .apartment-slider {
    width: 100%;

    * {
      outline: none;
    }

    img {
      object-fit: cover;
    }
  }

  .apartment-image {
    width: 100%;
    margin: 0;
    padding: 5px;
    aspect-ratio: 1;
    border-radius: 10px;
  }

  .slick-arrow {
    height: 30px;
    width: 30px;
    border-radius: 5px;
    padding: 5px;

    &:hover {
      opacity: 0.75;
      background-color: #0000002b;
    }
  }

  .slick-next {
    right: 10px;
    z-index: 3;

    * {
      color: rgb(230, 230, 230);
    }
  }

  .slick-prev {
    left: 10px;
    z-index: 3;

    * {
      color: rgb(230, 230, 230);
    }
  }

  .address,
  .occupancy,
  .price {
    margin-bottom: 15px;
    font-size: 18px;

    strong {
      font-weight: bold;
    }
  }

  .icon {
    margin-right: 5px;
    color: #333;
  }

  .details {
    margin-bottom: 20px;

    h3 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    p {
      font-size: 16px;
      line-height: 1.7;
      margin-bottom: 10px;
    }
  }

  .occupancy,
  .price {
    font-size: 17.5px;
    line-height: 1.5;
    margin-bottom: 10px;

    strong {
      font-size: 18px;
      font-weight: 600;
    }
  }

  @media (max-width: 1300px) {
    .info-block,
    .slick-slider {
      // background-color: red;
      min-width: calc(50% - 25px);
    }

    .slick-slider {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: 1000px) {
    h2 {
      font-size: 26px;
    }

    .horizontal-blocks {
      flex-direction: column;
      gap: 30px;

      > div {
        width: 100%;
      }

      .slick-slider {
        min-width: 100%;
        max-height: 55vh;
        left: 0;
        right: 0;
        margin: auto;

        img {
          max-height: 55vh;
          object-fit: cover;
        }
      }

      .info-block {
        min-width: 100%;
      }
    }

    .map-container {
      width: 100%;
      aspect-ratio: 2;
      min-height: fit-content;
    }
  }

  @media (max-width: 768px) {
    padding: 100px 15px;

    h2 {
      font-size: 22px;
    }

    .details h3 {
      font-size: 20px;
    }

    .address,
    .occupancy,
    .price {
      font-size: 16px;
    }

    .map-container {
      height: 150px;
    }

    .slick-slider {
      min-width: 100%;
      max-height: 40vh;

      img {
        max-height: 40vh;
      }
    }
  }
}
