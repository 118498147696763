.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 75px;
  padding-bottom: 20px;
  background: transparent;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  transition: background-color 0.3s ease, padding 0.3s ease;

  a {
    text-decoration: none;
  }

  &--scrolled {
    background-color: rgb(55, 55, 55);
    padding: 5px 75px;

    & svg {
      color: white;
    }
  }

  &--icon-white svg {
    color: white;
  }

  h1 {
    // font-family: "Tangerine", cursive;
    // font-size: 44px;
    // font-weight: 400;
    font-family: "Aleo", serif;
    font-size: 34px;
    font-weight: 300;

    line-height: 20px;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;

    text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.075);

    &:hover {
      opacity: 0.75;
    }
  }

  .burger-icon {
    background: none;
    border: none;
    cursor: pointer;
    z-index: 20;
    transition: all 0.3s ease;

    &:hover {
      opacity: 0.75;
    }
  }

  @media (max-width: 768px) {
    background-color: rgb(55, 55, 55);
    padding: 5px 25px;

    h1 {
      // font-size: 36px;
      font-size: 26px;
    }

    .burger-icon {
      color: white;
    }
  }
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(55, 55, 55);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 50% 50%;
  transform: translateY(-100%);
  transition: all 0.3s ease-in-out;
  z-index: 15;
  text-align: center;

  &--open {
    transform: translateY(0);
    border-radius: 0;
  }

  .menu ul {
    list-style: none;
    padding: 0;

    .category-name {
      color: rgb(170, 170, 170);
      font-size: 22px;

      &:not(:first-child) {
        margin-top: 35px;
      }
    }

    li {
      margin: 15px 0;

      a {
        color: white;
        font-size: 24px;
        transition: color 0.3s;

        &:hover {
          color: #f0a500;
        }
      }
    }
  }

  .kontakt-category {
    border-top: 1px solid rgb(170, 170, 170);
    padding: 15px 60px;
    margin: 5px;

    a {
      color: white;
      font-size: 24px;
      transition: color 0.3s;

      &:hover {
        color: #f0a500;
      }
    }
  }

  // .kontakt-category > * {
  //   border: 2px solid grey;
  // }

  .contact-info {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
    bottom: 30px;
    padding: 5px 15px;
    align-items: center;
    justify-content: center;
    // background-color: red;

    p {
      display: flex;
      align-items: center;
      gap: 5px;

      a {
        color: white;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .contact-icon {
      font-size: 18px;
      color: white;
    }
  }
}
