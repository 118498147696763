.datenschutz-content {
  padding: 20px;
  color: #3a3a3a;

  p {
    margin: 10px 0;
  }

  h4 {
    color: #2b2b2b;
    margin: 20px 0 10px 0;
  }

  h5 {
    color: #2b2b2b;
    margin: 10px 0;
  }

  a {
    color: #718476;
    text-decoration: none;
  }
}
