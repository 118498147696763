.categories-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #546a58;
  padding: 75px 25px 100px 25px;

  h2 {
    font-size: 34px;
    font-weight: 300;
    color: white;
    letter-spacing: 0.5px;
    margin-bottom: 50px; /* Отступ после заголовка */
  }

  a {
    text-decoration: none;
    cursor: auto;
  }

  .categories-cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* По умолчанию 4 карточки в ряд */
    gap: 15px;
    justify-items: center;

    div {
      display: flex;
      flex-direction: column;
      align-items: start;
      overflow: hidden; /* Скрыть излишки изображения при масштабировании */
    }

    h4 {
      font-size: 22px;
      font-weight: 400;
      color: white;
      margin-top: 30px; /* Отступ для текста под картинкой */
      padding-bottom: 10px;
      display: block;
      width: 100%;
    }

    img {
      width: 300px; /* Фиксированный размер для всех картинок */
      height: 300px;
      object-fit: cover; /* Обрезка изображения по контейнеру */
      transform: scale(1.025); /* Увеличение изображения на 5% */
      transition: transform 0.4s ease-in-out; /* Плавный переход */
      object-fit: cover;

      &:hover {
        transform: scale(1); /* Возврат к исходному размеру при наведении */
      }
    }
  }

  /* Медиа-запросы для адаптации под разные экраны */

  @media (max-width: 1300px) {
    .categories-cards {
      grid-template-columns: repeat(4, 1fr);

      & > div {
        width: calc((100vw - 60px) / 4 - 15px);
      }
    }

    img {
      max-width: 100%;
      aspect-ratio: 1;
      max-height: fit-content;
      min-width: 100%;
      min-height: max-content;
    }
  }

  @media (max-width: 1000px) {
    .categories-cards {
      grid-template-columns: repeat(2, 1fr);

      & > div {
        width: calc((100vw - 60px) / 2 - 15px);
        margin-bottom: 45px;
        border-bottom: 2px solid #718476;
        text-align: center;
      }
    }
  }

  @media (max-width: 480px) {
    .categories-cards {
      grid-template-columns: 1fr; /* 1 карточка в ряд */

      & > div {
        width: calc(100vw - 60px);
      }
    }
  }
}

@media (max-width: 896px) {
  .categories-section {
    padding: 50px 10px 50px 10px;
  }
}
